body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 300;
}

h2 {
  color: #4c95fc;
  margin-top: 30px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}

h2.MuiTypography-h6 {
  color: white;
}

h3 {
  font-weight: 500;
}

emph {
  font-weight: 600
}